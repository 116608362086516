import { Tabs,Tooltip} from 'antd';
import { useState,useEffect  } from "react";
import AccountApi from '../api/AccountApi'
import StatApi from '../api/StatApi'
import MinerApi from '../api/MinerApi'
import type { ColumnsType } from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/DetailPage.css'
import '../common/css/ArkreenMain.css'
import '../common/css/ArkreenSelect.css'
import {formatLongString,formatVisualizationDate,formatMinerTypeTag,formatStatusIcon,formatDate, formatElectricity2} from '@/utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import ArkreenDetailTd from '../components/ArkreenDetailTd'
import type { TabsProps } from 'antd';
import { useNavigate,useLocation } from 'react-router-dom'
import {RewardIcon,MinerLineIcon,TimestampIcon} from '@/icons/ArkreenIcon'
import { useRecoilState } from "recoil";
import { AccountsState } from "@/store/atoms";
import moment from 'moment'
import ArkreenInfoHeaderCard from '../components/ArkreenInfoHeaderCard'
import ArkreenStackAreaChart from '../components/ArkreenStackAreaChart'
import {getDates} from '@/utils/DateUtils'
import {TOKEN_NAME} from "@/store/config";

const isShowAccount = process.env.REACT_APP_SHOW_ACCOUNTS

interface DataType {
  address: string;
  nonce: number;
  accountType: number;
  earningMint: string;
  earningService: string;
  withdrawAmount: string;
  amountMiner: number;
}

function AccountDetail(){

  const location  = useLocation()
  const navigate  = useNavigate()
  const accountAddress = location.pathname.replace('/account/','')

  function showMinerDetail(address:string){
    navigate('/miner/'+address)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width:'180px',
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'minerType',
      key: 'minerType',
      width:'160px'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width:'150px'
    },
    {
      title: 'Maker',
      dataIndex: 'maker',
      key: 'maker',
      width:'180px'
    },
    {
      title: 'Reward('+TOKEN_NAME+')',
      dataIndex: 'totalReward',
      key: 'totalReward',
      width:'180px'
    },
    {
      title: 'Peak Power(W)',
      dataIndex: 'capacity',
      key: 'capacity',
      width:'160px'
    },
    {
      title: 'Energy(kWh)',
      dataIndex: 'totalEnergyGeneration',
      key: 'totalEnergyGeneration',
      width:'160px'
    },
    {
      title: 'Onboarding Time',
      dataIndex: 'onboardTime',
      key: 'onboardTime',
      width:'180px'
    },
    ];
  
  const [minerList,setMinerList] = useState([])
  const utm = new URLSearchParams(location.search)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
  const [accounts] = useRecoilState(AccountsState)
  const [growthDisplay, setGrowthDisplay] = useState('block')
  const [data, setData] = useState<any>([]);
  const [userInfo,setUserInfo] = useState<any>({
    address: undefined,
    nonce: undefined,
    accountType: undefined,
    earningMint: undefined,
    earningService: undefined,
    withdrawAmount: undefined,
    amountMiner: undefined
   })

  async function getMiners() {
    const data = {
      address:accountAddress,
      offset:currentPage,
    }
    setLoading(true)
    const rsp:any = await MinerApi.getMinerListByOwner(data);
    if(!rsp.error){
      rsp.result.forEach((item: any,index: number) => {
        const address = item.address;
        rsp.result[index].key = item.address
        rsp.result[index].address = <span onClick={() => {showMinerDetail(address)}} className="content-td-link">{formatLongString(address,7,7)}</span>
        rsp.result[index].minerType = <span>{formatMinerTypeTag(Number(item.minerType))}</span>
        rsp.result[index].maker = item.maker
        rsp.result[index].totalReward = <span>{Number(item.totalReward).toLocaleString()}</span>
        rsp.result[index].capacity = <span>{Number(item.peakPower/1000).toLocaleString()}</span>
        rsp.result[index].totalEnergyGeneration = <span>{formatElectricity2(Number(item.totalEnergyGeneration)/1000)}</span>
        rsp.result[index].onboardTime = <span>{formatVisualizationDate(item.onboardTime)}</span>
        const status =item.status;
        const runStatus =item.runStatus;
        if(status===2){
          if(runStatus === 0){
            rsp.result[index].status =  formatStatusIcon('success','Normal')
          }else{
            rsp.result[index].status = formatStatusIcon('warning','Abnormal')
          }
        }else if(status===3){
          rsp.result[index].status = formatStatusIcon('danger','Terminated')
        }else {
          rsp.result[index].status = formatStatusIcon('warning','Abnormal')
        }
      })
      setMinerList(rsp.result)
     }
    setLoading(false)
 }

  async function getAccountInfo() {
    //
    const index = accounts.findIndex((account) => account.address === accountAddress);
    if(index === -1){
      const rsp:any = await AccountApi.getAccountByAddress(accountAddress);
      if(!rsp.error){
        setUserInfo(rsp.result)
      }else{
        navigate("/404")
      }
    }else{
      const userinfo = {
        address: accounts[index].address,
        nonce: accounts[index].nonce,
        accountType: accounts[index].accountType,
        earningMint: accounts[index].earningMint,
        earningService: accounts[index].earningService,
        withdrawAmount: accounts[index].withdrawAmount,
        minersAmount: accounts[index].amountMiner,
        createTime: accounts[index].createTime,
      }
      setUserInfo(userinfo)
    }
  }

  async function stat(){
    //
    const startTime = formatDate(userInfo.createTime*1000,'yyyyMMdd');
    //const endTime = formatDate(Date.now(),'yyyyMMdd');
    const endTime = moment.utc().format('yyyyMMDD')
    const data = {
      address: userInfo.address,
      startTime: startTime,
      endTime:  endTime,
    }
    const days = getDates(new Date(userInfo.createTime*1000), new Date())
  
    const dailyReward:any = await StatApi.statOwnerDailyReward(data);
    if(dailyReward.error){
      setGrowthDisplay('none');
    }else{
      const dateArr:any = []
      const dailyRewardObj:any = {}
      dailyReward.result.forEach((item:any) => {
        dailyRewardObj[item.dataDate] = item.rewardTotal
      });
      const hideTime = moment.utc().format('yyyy-MM-DD')
      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        const dayStr = moment(day).format('yyyy-MM-DD')
        if(hideTime !== dayStr){
          var reward = 0
          if(dailyRewardObj[dayStr]) {
            reward = Number(dailyRewardObj[dayStr])
          }
          dateArr.push({dataDate:dayStr, value:reward, seriesField:' Reward'})
        }
      }
      setData(dateArr)
    }
  }

  const items: TabsProps['items'] = [{key: 'miners',label: 'Miners'}];

  useEffect(() => { if(userInfo.createTime){stat()} },[userInfo])//eslint-disable-line
  useEffect(() => { if(isShowAccount==='true'){getMiners()} },[accountAddress,currentPage])//eslint-disable-line
  useEffect(() => { getAccountInfo()},[location.pathname])//eslint-disable-line

  return (
    <div className='arkreen-main'>
      <div className='detail-header'>
        <ArkreenInfoHeaderCard title='Account' value={accountAddress}/>
        <div style={{paddingBlockStart:'20px'}}>
          <ArkreenDetailTd icon={<RewardIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Total Reward" content={(userInfo.withdrawAmount && userInfo.earningMint)?(Number(Number(Number(userInfo.earningMint)+Number(userInfo.withdrawAmount)).toFixed(2)).toLocaleString()+" "+TOKEN_NAME):''} />
          {
            isShowAccount === 'true' && <ArkreenDetailTd icon={<MinerLineIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Miners" content={userInfo.amountMiner} />
          }
          <ArkreenDetailTd icon={<TimestampIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Age" content={userInfo.createTime?<Tooltip placement="right" title={formatDate(userInfo.createTime?userInfo.createTime*1000:0,'yyyy-MM-dd HH:mm:ss')}>{formatVisualizationDate(Number(userInfo.createTime))}</Tooltip>:''} />
        </div>
      </div>
      <div style={{display:growthDisplay}} className='detail-table-box-tx detail-table-box'>
        <ArkreenStackAreaChart height='192px' title='Reward' data={data} xField='dataDate' yField='value' seriesField='seriesField' lineColor={['#00913A']} />
      </div>
      {
        isShowAccount === 'true' && <div className='detail-table-box'>
          <Tabs style={{marginTop:'-12px'}} defaultActiveKey="1" items={items}/>
          <ArkreenTable scroll={{ x: 1350 }} pageSize={Number(userInfo.minersAmount)} currentPage={currentPage} columns={columns} datalist={minerList} loading={loading} onPageChange={setCurrentPage}/>
        </div>
      }
      
    </div>
  );
}

export default AccountDetail;